/**
 * @generated SignedSource<<7126bb10749468fe553df8afbd4e0146>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type Category = "INVESTMENT_BANKING" | "TECH";
export type LayoutTopBarProblemUnitViewProgressModalQuery$variables = {
  category: Category;
  userID?: string | null | undefined;
};
export type LayoutTopBarProblemUnitViewProgressModalQuery$data = {
  readonly easy: {
    readonly problem_progress_status_problems: {
      readonly count: number;
    } | null | undefined;
  } | null | undefined;
  readonly hard: {
    readonly problem_progress_status_problems: {
      readonly count: number;
    } | null | undefined;
  } | null | undefined;
  readonly medium: {
    readonly problem_progress_status_problems: {
      readonly count: number;
    } | null | undefined;
  } | null | undefined;
  readonly totalEasy: {
    readonly count: number;
  };
  readonly totalHard: {
    readonly count: number;
  };
  readonly totalMedium: {
    readonly count: number;
  };
};
export type LayoutTopBarProblemUnitViewProgressModalQuery = {
  response: LayoutTopBarProblemUnitViewProgressModalQuery$data;
  variables: LayoutTopBarProblemUnitViewProgressModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "category"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userID"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "userID"
  }
],
v3 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "equals",
      "variableName": "category"
    }
  ],
  "kind": "ObjectValue",
  "name": "category"
},
v4 = [
  (v3/*: any*/),
  {
    "kind": "Literal",
    "name": "difficulty",
    "value": {
      "equals": "EASY"
    }
  }
],
v5 = {
  "kind": "Literal",
  "name": "progress_status",
  "value": "COMPLETED"
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
],
v7 = {
  "alias": null,
  "args": [
    {
      "fields": (v4/*: any*/),
      "kind": "ObjectValue",
      "name": "problem_where"
    },
    (v5/*: any*/)
  ],
  "concreteType": "ProblemConnection",
  "kind": "LinkedField",
  "name": "problem_progress_status_problems",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v8 = [
  (v3/*: any*/),
  {
    "kind": "Literal",
    "name": "difficulty",
    "value": {
      "equals": "MEDIUM"
    }
  }
],
v9 = {
  "alias": null,
  "args": [
    {
      "fields": (v8/*: any*/),
      "kind": "ObjectValue",
      "name": "problem_where"
    },
    (v5/*: any*/)
  ],
  "concreteType": "ProblemConnection",
  "kind": "LinkedField",
  "name": "problem_progress_status_problems",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v10 = [
  (v3/*: any*/),
  {
    "kind": "Literal",
    "name": "difficulty",
    "value": {
      "equals": "HARD"
    }
  }
],
v11 = {
  "alias": null,
  "args": [
    {
      "fields": (v10/*: any*/),
      "kind": "ObjectValue",
      "name": "problem_where"
    },
    (v5/*: any*/)
  ],
  "concreteType": "ProblemConnection",
  "kind": "LinkedField",
  "name": "problem_progress_status_problems",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v12 = {
  "alias": "totalEasy",
  "args": [
    {
      "fields": (v4/*: any*/),
      "kind": "ObjectValue",
      "name": "where"
    }
  ],
  "concreteType": "ProblemConnection",
  "kind": "LinkedField",
  "name": "multifetch__problem",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v13 = {
  "alias": "totalMedium",
  "args": [
    {
      "fields": (v8/*: any*/),
      "kind": "ObjectValue",
      "name": "where"
    }
  ],
  "concreteType": "ProblemConnection",
  "kind": "LinkedField",
  "name": "multifetch__problem",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v14 = {
  "alias": "totalHard",
  "args": [
    {
      "fields": (v10/*: any*/),
      "kind": "ObjectValue",
      "name": "where"
    }
  ],
  "concreteType": "ProblemConnection",
  "kind": "LinkedField",
  "name": "multifetch__problem",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LayoutTopBarProblemUnitViewProgressModalQuery",
    "selections": [
      {
        "alias": "easy",
        "args": (v2/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "fetch__user",
        "plural": false,
        "selections": [
          (v7/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "medium",
        "args": (v2/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "fetch__user",
        "plural": false,
        "selections": [
          (v9/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "hard",
        "args": (v2/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "fetch__user",
        "plural": false,
        "selections": [
          (v11/*: any*/)
        ],
        "storageKey": null
      },
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "LayoutTopBarProblemUnitViewProgressModalQuery",
    "selections": [
      {
        "alias": "easy",
        "args": (v2/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "fetch__user",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v15/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "medium",
        "args": (v2/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "fetch__user",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v15/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "hard",
        "args": (v2/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "fetch__user",
        "plural": false,
        "selections": [
          (v11/*: any*/),
          (v15/*: any*/)
        ],
        "storageKey": null
      },
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/)
    ]
  },
  "params": {
    "cacheID": "9c7f12a68b8ab1c8863a94e42d6b8a37",
    "id": null,
    "metadata": {},
    "name": "LayoutTopBarProblemUnitViewProgressModalQuery",
    "operationKind": "query",
    "text": "query LayoutTopBarProblemUnitViewProgressModalQuery(\n  $userID: ID\n  $category: Category!\n) {\n  easy: fetch__user(id: $userID) {\n    problem_progress_status_problems(progress_status: COMPLETED, problem_where: {category: {equals: $category}, difficulty: {equals: EASY}}) {\n      count\n    }\n    id\n  }\n  medium: fetch__user(id: $userID) {\n    problem_progress_status_problems(progress_status: COMPLETED, problem_where: {category: {equals: $category}, difficulty: {equals: MEDIUM}}) {\n      count\n    }\n    id\n  }\n  hard: fetch__user(id: $userID) {\n    problem_progress_status_problems(progress_status: COMPLETED, problem_where: {category: {equals: $category}, difficulty: {equals: HARD}}) {\n      count\n    }\n    id\n  }\n  totalEasy: multifetch__problem(where: {category: {equals: $category}, difficulty: {equals: EASY}}) {\n    count\n  }\n  totalMedium: multifetch__problem(where: {category: {equals: $category}, difficulty: {equals: MEDIUM}}) {\n    count\n  }\n  totalHard: multifetch__problem(where: {category: {equals: $category}, difficulty: {equals: HARD}}) {\n    count\n  }\n}\n"
  }
};
})();

(node as any).hash = "481720e6eb3db2e5e9f982076c2ec2bf";

export default node;
