"use strict";

import { FC, ReactElement, useContext, useEffect, useState } from "react";
import { useLazyLoadQuery, useRefetchableFragment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import {
  Button,
  Skeleton,
  Stack,
  Typography,
  TypographyOwnProps,
} from "@mui/material";
import Suspensable from "../../../common/Suspensable";
import useUser from "../../../../user/useUser";
import LayoutContext from "../../LayoutContext";
import TooltipWithStopPropogation from "../../../common/TooltipWithStopPropogation";
import {
  Category,
  ProblemDifficulty,
} from "../../../landing_view/__generated__/LandingViewInviniteAutoplayCarouselQuery.graphql";
import useTheme, { ThemeType } from "../../../../theme/useTheme";
import { LayoutTopBarProblemUnitViewProgress_content$key } from "./__generated__/LayoutTopBarProblemUnitViewProgress_content.graphql";
import { LayoutTopBarProblemUnitViewProgressQuery } from "./__generated__/LayoutTopBarProblemUnitViewProgressQuery.graphql";
import { LayoutTopBarProblemUnitViewProgressRefetchQuery } from "./__generated__/LayoutTopBarProblemUnitViewProgressRefetchQuery.graphql";
import LayoutTopBarProblemunitViewProgressModal from "./LayoutTopBarProblemUnitViewProgressModal";

export type DifficultyProblemStats = {
  difficulty: ProblemDifficulty;
  label: string;
  count: number;
  totalCount: number;
  color: TypographyOwnProps["color"];
};

const LayoutTopBarProblemUnitViewProgressContent: FC<Props> = ({
  category,
}): ReactElement => {
  const [open, setOpen] = useState(false);
  const { shouldTopBarProgressUpdate, setShouldTopBarProgressUpdate } =
    useContext(LayoutContext);
  const { user } = useUser();
  const { themeType } = useTheme();
  const dataFragment =
    useLazyLoadQuery<LayoutTopBarProblemUnitViewProgressQuery>(
      graphql`
        query LayoutTopBarProblemUnitViewProgressQuery(
          $userID: ID
          $category: Category!
        ) {
          ...LayoutTopBarProblemUnitViewProgress_content
            @arguments(userID: $userID, category: $category)
        }
      `,
      { userID: user?.id, category },
    );

  const [data, refetch] = useRefetchableFragment<
    LayoutTopBarProblemUnitViewProgressRefetchQuery,
    LayoutTopBarProblemUnitViewProgress_content$key
  >(
    graphql`
      fragment LayoutTopBarProblemUnitViewProgress_content on Query
      @refetchable(queryName: "LayoutTopBarProblemUnitViewProgressRefetchQuery")
      @argumentDefinitions(
        userID: { type: "ID" }
        category: { type: "Category!" }
      ) {
        fetch__user(id: $userID) {
          problem_progress_status_problems(
            progress_status: COMPLETED
            problem_where: { category: { equals: $category } }
          ) {
            count
          }
        }
        multifetch__problem(where: { category: { equals: $category } }) {
          count
        }
      }
    `,
    dataFragment,
  );

  useEffect(() => {
    if (shouldTopBarProgressUpdate) {
      refetch({ userID: user?.id, category }, { fetchPolicy: "network-only" });
      setShouldTopBarProgressUpdate(false);
    }
  }, [
    category,
    refetch,
    setShouldTopBarProgressUpdate,
    shouldTopBarProgressUpdate,
    user?.id,
  ]);

  const {
    fetch__user,
    multifetch__problem: { count: totalProblemCount },
  } = data;

  return (
    <>
      <TooltipWithStopPropogation title="View details">
        <Button
          variant={themeType === ThemeType.LIGHT ? "contained" : "text"}
          onClick={() => setOpen(true)}
          sx={{
            padding: 0,
            boxShadow: "none",
            "&:hover": { boxShadow: "none" },
          }}
        >
          <Stack alignItems="center">
            <Stack direction="row" gap={1}>
              <Typography color="white" fontWeight="bold">
                {fetch__user?.problem_progress_status_problems?.count ?? 0}
              </Typography>
              <Typography color="textDisabled">/</Typography>
              <Typography color="textDisabled">{totalProblemCount}</Typography>
            </Stack>
            <Typography variant="subtitle2" color="textDisabled">
              Total
            </Typography>
          </Stack>
        </Button>
      </TooltipWithStopPropogation>
      {open && (
        <LayoutTopBarProblemunitViewProgressModal
          category={category}
          setOpen={setOpen}
        />
      )}
    </>
  );
};

interface Props {
  category: Category;
}

const LayoutTopBarProblemUnitViewProgress: FC<Props> = (
  props,
): ReactElement => {
  return (
    <Suspensable
      fallback={
        <Stack alignItems="center">
          <Stack direction="row" gap={1}>
            <Skeleton variant="text" width={40} />
            <Typography color="textDisabled">/</Typography>
            <Skeleton variant="text" width={40} />
          </Stack>
          <Typography variant="subtitle2" color="textDisabled">
            Total
          </Typography>
        </Stack>
      }
    >
      <LayoutTopBarProblemUnitViewProgressContent {...props} />
    </Suspensable>
  );
};

export default LayoutTopBarProblemUnitViewProgress;
