"use strict";

import { FC, ReactElement, ReactNode, useCallback } from "react";
import { Button, ButtonProps } from "@mui/material";
import { Stars as StarsIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { PREMIUM_COLOR } from "../../../constants";
import { useAuth0 } from "@auth0/auth0-react";

interface Props {
  children: ReactNode;
  startIcon?: ButtonProps["endIcon"];
  size?: ButtonProps["size"];
  sx?: ButtonProps["sx"];
}

const SubscribeButton: FC<Props> = ({
  children,
  startIcon,
  size,
  sx,
}): ReactElement => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    if (isAuthenticated) {
      navigate("/pricing");
    } else {
      loginWithRedirect();
    }
  }, [isAuthenticated, loginWithRedirect, navigate]);

  return (
    <Button
      variant="contained"
      size={size}
      startIcon={startIcon ?? <StarsIcon />}
      onClick={onClick}
      sx={{ backgroundColor: PREMIUM_COLOR, color: "white", ...sx }}
    >
      {children}
    </Button>
  );
};

export default SubscribeButton;
