"use strict";

import React, { useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Stack,
  Toolbar,
  styled,
} from "@mui/material";
import { FC, ReactElement, useCallback } from "react";
import ProfileIconButton from "../ProfileIconButton";
import useLog from "../../../log/useLog";
import ThemeSwitch from "../../common/theme_switch/ThemeSwitch";
import { isMobile } from "react-device-detect";
import LayoutTopBarProblemUnitViewProgress from "./problem_unit_view_progress/LayoutTopBarProblemUnitViewProgress";
import useCategory from "../useCategory";
import useModuleType, { ModuleType } from "../useModuleType";
import useUser from "../../../user/useUser";
import SubscribeButton from "../../common/subscribe_button/SubscribeButton";
import { useLocation } from "react-router-dom";

const LayoutTopBarMenuButton = React.lazy(
  () => import("./menu_button/LayoutTopBarMenuButton"),
);
const LayoutTopBarBreadcrumbs = React.lazy(
  () => import("./breadcrubs/LayoutTopBarBreadcrumbs"),
);

const LayoutTopBar: FC = (): ReactElement => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const log = useLog();
  const { user } = useUser();
  const category = useCategory();
  const moduleType = useModuleType();
  const location = useLocation();
  const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);

  const { isAdmin, hasAccess } = user ?? {};

  const isShowSubscribeButton = useMemo(() => {
    const path = location.pathname.split("/")[1];
    return hasAccess === false && path !== "pricing" && path !== "profile";
  }, [hasAccess, location.pathname]);

  const onLoginButtonClick = useCallback(() => {
    loginWithRedirect();
    log({ viewType: null, eventType: "ON_LOGIN_ICON_BUTTON_CLICK" });
  }, [loginWithRedirect, log]);

  return (
    <>
      <AppBar>
        <Toolbar>
          <Stack direction="row" flexGrow={1} gap={1} alignItems="center">
            {isMobile || isAdmin === true ? (
              <LayoutTopBarMenuButton />
            ) : (
              <LayoutTopBarBreadcrumbs />
            )}
          </Stack>
          <Stack direction="row" alignItems="center">
            {isShowSubscribeButton && (
              <SubscribeButton sx={{ marginRight: 2 }}>Premium</SubscribeButton>
            )}
            {!isAuthenticated && <ThemeSwitch />}
            {isAuthenticated &&
              category != null &&
              moduleType === ModuleType.QUESTIONS && (
                <Box marginRight={2}>
                  <LayoutTopBarProblemUnitViewProgress category={category} />
                </Box>
              )}
            {isLoading ? (
              <CircularProgress />
            ) : isAuthenticated ? (
              <ProfileIconButton />
            ) : (
              <Button color="inherit" onClick={onLoginButtonClick}>
                Login
              </Button>
            )}
          </Stack>
        </Toolbar>
      </AppBar>
      <Offset />
    </>
  );
};

export default LayoutTopBar;
