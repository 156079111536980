"use strict";

import React from "react";

type LayoutContextType = {
  topBarHeight: number | null;
  shouldTopBarProgressUpdate: boolean;
  setShouldTopBarProgressUpdate: (shouldUpdate: boolean) => void;
  shouldSearchViewTableUpdate: boolean;
  setShouldSearchViewTableUpdate: (shouldUpdate: boolean) => void;
};

export default React.createContext<LayoutContextType>({
  topBarHeight: null,
  shouldTopBarProgressUpdate: false,
  setShouldTopBarProgressUpdate: () => {},
  shouldSearchViewTableUpdate: false,
  setShouldSearchViewTableUpdate: () => {},
});
