"use strict";

import { FC, ReactElement, useMemo, useState } from "react";
import LayoutTopBar from "./top_bar/LayoutTopBar";
import LayoutContext from "./LayoutContext";
import { useMeasure } from "@uidotdev/usehooks";

interface Props {
  children: React.ReactNode;
}

const Layout: FC<Props> = ({ children }: Props): ReactElement => {
  const [shouldTopBarProgressUpdate, setShouldTopBarProgressUpdate] =
    useState(false);
  const [shouldSearchViewTableUpdate, setShouldSearchViewTableUpdate] =
    useState(false);
  const [ref, { height }] = useMeasure();

  const contextValue = useMemo(
    () => ({
      topBarHeight: height,
      shouldTopBarProgressUpdate,
      setShouldTopBarProgressUpdate,
      shouldSearchViewTableUpdate,
      setShouldSearchViewTableUpdate,
    }),
    [height, shouldSearchViewTableUpdate, shouldTopBarProgressUpdate],
  );

  return (
    <LayoutContext.Provider value={contextValue}>
      <span ref={ref} style={{ display: "block" }}>
        <LayoutTopBar />
      </span>
      {children}
    </LayoutContext.Provider>
  );
};

export default Layout;
