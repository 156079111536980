"use strict";

import { FC, ReactElement, useMemo } from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { Grid2, Stack, TypographyOwnProps } from "@mui/material";
import Suspensable from "../../../common/Suspensable";
import useUser from "../../../../user/useUser";
import ScrollableModal from "../../../common/modal/ScrollableModal";
import {
  Category,
  ProblemDifficulty,
} from "../../../landing_view/__generated__/LandingViewInviniteAutoplayCarouselQuery.graphql";
import useTheme from "../../../../theme/useTheme";
import LayoutTopBarProblemUnitViewProgressPieChart from "./LayoutTopBarProblemUnitViewProgressPieChart";
import LayoutTopBarProblemUnitViewProgressTable from "./LayoutTopBarProblemUnitViewProgressTable";
import LayoutTopBarProblemUnitViewProgressModalRestartSessionButton from "./LayoutTopBarProblemUnitViewProgressModalRestartSessionButton";
import { LayoutTopBarProblemUnitViewProgressModalQuery } from "./__generated__/LayoutTopBarProblemUnitViewProgressModalQuery.graphql";

export type DifficultyProblemStats = {
  difficulty: ProblemDifficulty;
  label: string;
  count: number;
  totalCount: number;
  color: TypographyOwnProps["color"];
};

const LayoutTopBarProblemUnitViewProgressModalContent: FC<Props> = ({
  category,
  setOpen,
}): ReactElement => {
  const { user } = useUser();
  const { theme } = useTheme();
  const { easy, medium, hard, totalEasy, totalMedium, totalHard } =
    useLazyLoadQuery<LayoutTopBarProblemUnitViewProgressModalQuery>(
      graphql`
        query LayoutTopBarProblemUnitViewProgressModalQuery(
          $userID: ID
          $category: Category!
        ) {
          easy: fetch__user(id: $userID) {
            problem_progress_status_problems(
              progress_status: COMPLETED
              problem_where: {
                category: { equals: $category }
                difficulty: { equals: EASY }
              }
            ) {
              count
            }
          }
          medium: fetch__user(id: $userID) {
            problem_progress_status_problems(
              progress_status: COMPLETED
              problem_where: {
                category: { equals: $category }
                difficulty: { equals: MEDIUM }
              }
            ) {
              count
            }
          }
          hard: fetch__user(id: $userID) {
            problem_progress_status_problems(
              progress_status: COMPLETED
              problem_where: {
                category: { equals: $category }
                difficulty: { equals: HARD }
              }
            ) {
              count
            }
          }
          totalEasy: multifetch__problem(
            where: {
              category: { equals: $category }
              difficulty: { equals: EASY }
            }
          ) {
            count
          }
          totalMedium: multifetch__problem(
            where: {
              category: { equals: $category }
              difficulty: { equals: MEDIUM }
            }
          ) {
            count
          }
          totalHard: multifetch__problem(
            where: {
              category: { equals: $category }
              difficulty: { equals: HARD }
            }
          ) {
            count
          }
        }
      `,
      {
        userID: user?.id,
        category,
      },
      { fetchPolicy: "network-only" },
    );
  const { count: easyCount = 0 } = easy?.problem_progress_status_problems ?? {};
  const { count: mediumCount = 0 } =
    medium?.problem_progress_status_problems ?? {};
  const { count: hardCount = 0 } = hard?.problem_progress_status_problems ?? {};
  const { count: totalEasyCount = 0 } = totalEasy ?? {};
  const { count: totalMediumCount = 0 } = totalMedium ?? {};
  const { count: totalHardCount = 0 } = totalHard ?? {};

  const difficultyProblemStats: DifficultyProblemStats[] = useMemo(
    () => [
      {
        difficulty: "EASY",
        label: "Easy",
        count: easyCount,
        totalCount: totalEasyCount,
        color: theme.palette.success.main,
      },
      {
        difficulty: "MEDIUM",
        label: "Medium",
        count: mediumCount,
        totalCount: totalMediumCount,
        color: theme.palette.warning.main,
      },
      {
        difficulty: "HARD",
        label: "Hard",
        count: hardCount,
        totalCount: totalHardCount,
        color: theme.palette.error.main,
      },
    ],
    [
      easyCount,
      hardCount,
      mediumCount,
      theme.palette.error.main,
      theme.palette.success.main,
      theme.palette.warning.main,
      totalEasyCount,
      totalHardCount,
      totalMediumCount,
    ],
  );

  return (
    <Grid2 container={true} rowGap={2}>
      <Grid2 size={7}>
        <LayoutTopBarProblemUnitViewProgressPieChart
          difficultyProblemStats={difficultyProblemStats}
        />
      </Grid2>
      <Grid2 size={5}>
        <LayoutTopBarProblemUnitViewProgressTable
          difficultyProblemStats={difficultyProblemStats}
        />
      </Grid2>
      <Grid2 size={12}>
        <Stack direction="row" justifyContent="end">
          <LayoutTopBarProblemUnitViewProgressModalRestartSessionButton
            onCompleted={() => setOpen(false)}
          />
        </Stack>
      </Grid2>
    </Grid2>
  );
};

interface Props {
  category: Category;
  setOpen: (open: boolean) => void;
}

const LayoutTopBarProblemUnitViewProgressModal: FC<Props> = ({
  category,
  setOpen,
}): ReactElement => {
  return (
    <ScrollableModal
      open={true}
      onClose={() => setOpen(false)}
      InnerProps={{ sx: { width: 500, minHeight: "fit-content" } }}
    >
      <Suspensable>
        <LayoutTopBarProblemUnitViewProgressModalContent
          category={category}
          setOpen={setOpen}
        />
      </Suspensable>
    </ScrollableModal>
  );
};

export default LayoutTopBarProblemUnitViewProgressModal;
