"use strict";

import {
  Accordion,
  AccordionDetails,
  AccordionDetailsProps,
  AccordionSummary,
} from "@mui/material";
import { FC, ReactElement, ReactNode } from "react";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";

interface Props {
  summary: ReactNode;
  children: ReactNode;
  onChange?: (event: React.SyntheticEvent, expanded: boolean) => void;
  accordionDetailsProps?: Omit<AccordionDetailsProps, "children">;
}

const SimpleAccordion: FC<Props> = ({
  summary,
  children,
  onChange,
  accordionDetailsProps,
}): ReactElement => {
  return (
    <Accordion
      disableGutters={true}
      slotProps={{ transition: { timeout: 0 } }}
      onChange={onChange}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {summary}
      </AccordionSummary>
      <AccordionDetails {...accordionDetailsProps}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default SimpleAccordion;
