"use strict";

import { graphql } from "babel-plugin-relay/macro";
import {
  fetchQuery,
  readInlineData,
  useMutation,
  useRelayEnvironment,
} from "react-relay/hooks";
import { useUserQuery } from "./__generated__/useUserQuery.graphql";
import { useCallback, useContext } from "react";
import UserContext from "./UserContext";
import {
  useUserMutation,
  useUserMutation$variables,
} from "./__generated__/useUserMutation.graphql";
import { useUserUpdateResumeMutation } from "./__generated__/useUserUpdateResumeMutation.graphql";
import { useUserFragment$key } from "./__generated__/useUserFragment.graphql";

const USER_FRAGMENT = graphql`
  fragment useUserFragment on User @inline {
    id
    email
    name
    display_id
    picture_url
    resume_url
    role
    is_subscribed
    is_group_subscription
  }
`;

export class User {
  id: string;
  email: string | null;
  displayId: string | null;
  name: string | null;
  pictureUrl: string | null;
  resumeUrl: string | null;
  hasAccess: boolean;
  isAdmin: boolean;
  isGroupSubscription: boolean;

  constructor(fragment: useUserFragment$key) {
    const {
      id,
      email,
      display_id,
      name,
      picture_url,
      resume_url,
      is_subscribed,
      is_group_subscription,
      role,
    } = readInlineData<useUserFragment$key>(USER_FRAGMENT, fragment);
    this.id = id;
    this.email = email ?? null;
    this.displayId = display_id ?? null;
    this.name = name ?? null;
    this.pictureUrl = picture_url ?? null;
    this.resumeUrl = resume_url ?? null;
    this.hasAccess = is_subscribed;
    this.isAdmin = role === "EDITOR";
    this.isGroupSubscription = is_group_subscription;
  }
}

export default function useUser(): {
  user: User | null;
  fetchUser: (id: string | null) => void;
  updateUser: [
    (
      variables: useUserMutation$variables,
      avatarFile?: File,
      onCompleted?: () => void,
    ) => void,
    boolean,
  ];
  updateUserResume: [
    (resumeFile: File, onCompleted?: () => void) => void,
    boolean,
  ];
} {
  const { user, setUser } = useContext(UserContext);
  const environment = useRelayEnvironment();
  const [commit, isInFlight] = useMutation<useUserMutation>(graphql`
    mutation useUserMutation($name: String, $displayId: String, $file: Upload) {
      update__user(name: $name, display_id: $displayId, avatar_file: $file) {
        ...useUserFragment
      }
    }
  `);
  const [commitUpdateResume, isUpdateResumeInFlight] =
    useMutation<useUserUpdateResumeMutation>(graphql`
      mutation useUserUpdateResumeMutation($file: Upload) {
        update__user_resume(resume_file: $file) {
          ...useUserFragment
        }
      }
    `);

  const fetchUser = useCallback(
    (id: string | null) => {
      if (id == null) {
        setUser(null);
      }
      return fetchQuery<useUserQuery>(
        environment,
        graphql`
          query useUserQuery($id: ID) {
            fetch__user(id: $id) {
              ...useUserFragment
            }
          }
        `,
        { id },
      ).subscribe({
        next: ({ fetch__user }) =>
          setUser(fetch__user == null ? null : new User(fetch__user)),
      }).unsubscribe;
    },
    [environment, setUser],
  );

  const updateUser = useCallback(
    (
      variables: useUserMutation$variables,
      avatarFile?: File,
      onCompleted?: () => void,
    ) =>
      commit({
        variables,
        uploadables: avatarFile == null ? undefined : { file: avatarFile },
        onCompleted: ({ update__user }) => {
          setUser(update__user == null ? null : new User(update__user));
          onCompleted?.();
        },
      }),
    [commit, setUser],
  );

  const updateUserResume = useCallback(
    (resumeFile: File, onCompleted?: () => void) =>
      commitUpdateResume({
        variables: {},
        uploadables: { file: resumeFile },
        onCompleted: ({ update__user_resume }) => {
          setUser(
            update__user_resume == null
              ? null
              : (new User(update__user_resume) ?? null),
          );
          onCompleted?.();
        },
      }),
    [commitUpdateResume, setUser],
  );

  return {
    user,
    fetchUser,
    updateUser: [updateUser, isInFlight],
    updateUserResume: [updateUserResume, isUpdateResumeInFlight],
  };
}
