"use strict";

import { FC, ReactElement } from "react";
import { Stars as StarsIcon } from "@mui/icons-material";
import { PREMIUM_COLOR } from "../../../constants";

interface Props {
  width: number;
  height: number;
}

const SubscriptionStarsIcon: FC<Props> = ({ width, height }): ReactElement => {
  return (
    <StarsIcon
      sx={{
        color: PREMIUM_COLOR,
        backgroundColor: "white",
        width,
        height,
        borderRadius: "50%",
      }}
    />
  );
};

export default SubscriptionStarsIcon;
