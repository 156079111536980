"use strict";

import { FC, ReactElement, useCallback, useContext, useState } from "react";
import { Button, Stack, Typography, TypographyOwnProps } from "@mui/material";
import ScrollableModal from "../../../common/modal/ScrollableModal";
import { ProblemDifficulty } from "../../../landing_view/__generated__/LandingViewInviniteAutoplayCarouselQuery.graphql";
import {
  Refresh as RefreshIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import { useMutation } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { LoadingButton } from "@mui/lab";
import LayoutContext from "../../LayoutContext";

export type DifficultyProblemStats = {
  difficulty: ProblemDifficulty;
  label: string;
  count: number;
  totalCount: number;
  color: TypographyOwnProps["color"];
};

interface Props {
  onCompleted: () => void;
}

const LayoutTopBarProblemUnitViewProgressModalRestartSessionButton: FC<
  Props
> = ({ onCompleted }): ReactElement => {
  const [open, setOpen] = useState(false);
  const { setShouldTopBarProgressUpdate, setShouldSearchViewTableUpdate } =
    useContext(LayoutContext);
  const [commit, isInFlight] = useMutation(graphql`
    mutation LayoutTopBarProblemUnitViewProgressModalRestartSessionButtonMutation {
      reset__user_problems_status {
        id
      }
    }
  `);

  const handleConfirm = useCallback(() => {
    commit({
      variables: {},
      onCompleted: () => {
        setOpen(false);
        onCompleted();
        setShouldTopBarProgressUpdate(true);
        setShouldSearchViewTableUpdate(true);
      },
    });
  }, [
    commit,
    onCompleted,
    setShouldSearchViewTableUpdate,
    setShouldTopBarProgressUpdate,
  ]);

  return (
    <>
      <Button
        variant="contained"
        color="inherit"
        size="small"
        endIcon={<SettingsIcon />}
        onClick={() => setOpen(true)}
      >
        Session
      </Button>
      <ScrollableModal
        open={open}
        onClose={() => setOpen(false)}
        InnerProps={{ sx: { width: 500, minHeight: "fit-content" } }}
      >
        <Stack direction="row" alignItems="center" gap={2}>
          <RefreshIcon color="success" fontSize="large" />
          <Typography variant="h5">Refresh Session</Typography>
        </Stack>
        <Typography>
          To make a fresh start, click the confirm button to refresh the
          session. Your past submissions will be kept intact and only the
          progress will be reset to 0.
        </Typography>
        <Stack direction="row" justifyContent="end" gap={2}>
          <Button
            variant="contained"
            color="inherit"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="success"
            disabled={isInFlight}
            loading={isInFlight}
            onClick={handleConfirm}
          >
            Confirm
          </LoadingButton>
        </Stack>
      </ScrollableModal>
    </>
  );
};

export default LayoutTopBarProblemUnitViewProgressModalRestartSessionButton;
