"use strict";

import { Avatar, Badge, Box } from "@mui/material";
import { FC, ReactElement, useMemo } from "react";
import { User } from "../../../user/useUser";
import SubscriptionStarsIcon from "../subscription_stars_icon/SubscriptionStarsIcon";

const styles = {
  avatar: {
    width: 32,
    height: 32,
  },
};

interface Props {
  user: User;
}

const ProfileAvatar: FC<Props> = ({
  user: { pictureUrl, displayId, hasAccess },
}): ReactElement => {
  const avatar = useMemo(
    () => (
      <Avatar
        src={pictureUrl ?? undefined}
        alt={displayId ?? undefined}
        sx={styles.avatar}
      />
    ),
    [displayId, pictureUrl],
  );

  return hasAccess ? (
    <Box>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        badgeContent={<SubscriptionStarsIcon width={12} height={12} />}
      >
        {avatar}
      </Badge>
    </Box>
  ) : (
    avatar
  );
};

export default ProfileAvatar;
