/**
 * @generated SignedSource<<253ee1b125b65f36ad8d9c2b83460d9b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type LayoutTopBarProblemUnitViewProgressModalRestartSessionButtonMutation$variables = Record<PropertyKey, never>;
export type LayoutTopBarProblemUnitViewProgressModalRestartSessionButtonMutation$data = {
  readonly reset__user_problems_status: {
    readonly id: string;
  } | null | undefined;
};
export type LayoutTopBarProblemUnitViewProgressModalRestartSessionButtonMutation = {
  response: LayoutTopBarProblemUnitViewProgressModalRestartSessionButtonMutation$data;
  variables: LayoutTopBarProblemUnitViewProgressModalRestartSessionButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "reset__user_problems_status",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LayoutTopBarProblemUnitViewProgressModalRestartSessionButtonMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LayoutTopBarProblemUnitViewProgressModalRestartSessionButtonMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "ab4ce7ea99af8ca7889e70dd25a499d7",
    "id": null,
    "metadata": {},
    "name": "LayoutTopBarProblemUnitViewProgressModalRestartSessionButtonMutation",
    "operationKind": "mutation",
    "text": "mutation LayoutTopBarProblemUnitViewProgressModalRestartSessionButtonMutation {\n  reset__user_problems_status {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "179572509bce7cc05dee272b4f019ac3";

export default node;
