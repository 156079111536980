/**
 * @generated SignedSource<<03c80f7f8919f4db65680d3ce9b9aff5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type Role = "EDITOR" | "PREMIUM_USER" | "USER";
import { FragmentRefs } from "relay-runtime";
export type useUserFragment$data = {
  readonly display_id: string | null | undefined;
  readonly email: string | null | undefined;
  readonly id: string;
  readonly is_group_subscription: boolean;
  readonly is_subscribed: boolean;
  readonly name: string | null | undefined;
  readonly picture_url: string | null | undefined;
  readonly resume_url: string | null | undefined;
  readonly role: Role;
  readonly " $fragmentType": "useUserFragment";
};
export type useUserFragment$key = {
  readonly " $data"?: useUserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useUserFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useUserFragment"
};

(node as any).hash = "f1724fac767b43017a993f48725d2506";

export default node;
